
























































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import NewDecoComponent from '../NewDecoComponent.vue';
import NewFishComponent from '../NewFishComponent.vue';
import NewSkinComponent from '../NewSkinComponent.vue';

@Component({
	components: {
		NewDecoComponent,
		NewFishComponent,
		NewSkinComponent,
	},
})
export default class NewsPost extends Vue {
}
